import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading, H2, Reviews, IconReviewStar } from '../../components';

import css from './ListingPage.module.css';
import classNames from 'classnames';

const SectionReviews = props => {
  const { reviews, fetchReviewsError, simpleLayout } = props;

  const reviewRate = reviews.length ? (Math.round(
    (reviews.reduce((total, item) => total + item.attributes.rating, 0) / reviews.length) * 10
  ) / 10).toFixed(1) : 0;

  if(simpleLayout) {
    return (
      <a className={classNames(css.sectionReviews, css.sectionReviewsSimple)} href="#reviews">
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin} className={css.reviewsTitle}>
          <FormattedMessage id="ListingPage.reviewsTitle" />
          <IconReviewStar
            key={`ListingPage.reviewsTitle-star`}
            className={css.reviewStar}
            isFilled={!!reviews?.length}
          />
          <span className={css.averageRatingCounter}>{reviewRate}</span>
          <span className={css.sectionReviewsCounter}>({reviews.length})</span>
        </Heading>
      </a>
    );
  } else {
    return (
      <div className={css.sectionReviews} id="reviews" >
        <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin} className={css.reviewsTitle}>
          <FormattedMessage id="ListingPage.reviewsTitle" />
          <IconReviewStar
            key={`ListingPage.reviewsTitle-star`}
            className={css.reviewStar}
            isFilled={!!reviews?.length}
          />
          <span className={css.averageRatingCounter}>{reviewRate}</span>
          <span className={css.sectionReviewsCounter}>({reviews.length})</span>
        </Heading>
        {fetchReviewsError ? (
          <H2 className={css.errorText}>
            <FormattedMessage id="ListingPage.reviewsError" />
          </H2>
        ) : null}
        <Reviews reviews={reviews} />
      </div>
    );
  }
};

export default SectionReviews;
